/* eslint-disable import/no-anonymous-default-export */
export default {
  Menu: {
    alertError: "Error! Something went wrong.",
    category: "category",
    yourCart: "Your Cart",
    emptyCart: "Your cart is empty, start by adding some items to your cart",
    discount: "Discount",
    subTotal: "Sub total",
    placeOrder: "PLACE ORDER",
    selectOne: "Select One",
    specialInstructions: "Special Instructions",
    addToCart: "ADD TO CART",
    viewCart: "VIEW CART",
    orderSummary: "Order Summary",
    applyPromo: "Apply Promo",
    details: "Details",
    paymentMethod: "Payment Method",
    cashOnDelivery: "Cash On Delivery",
    deliveryDetails: "Delivery Details",
    firstName: "First Name",
    lastName: "Last Name",
    address: "Address",
    city: "City",
    area: "Area",
    selectCity: "Select City",
    selectArea: "Select Area",
    contactNumber: "Contact Number",
    emailAddress: "Email Address",
    deliveryInstructions: "Delivery Instructions",
    cancel: "Close",
    confirmOrder: "Confirm Order",
    delivery: "Delivery",
    takeaway: "Take Away",
    tax: "Tax",
    itemTotal: "Item Total",
    checkMenu: "Check Menu",
    closedMessage:
      "Our team took some time off spend some quality time with their families, but we would be back to serve your appetite tomorrow.",
    allFieldsMandatory: "all fields are mandatory",
    deliveryCharges: "Delivery Charges",
  },
  Receipt: {
    customerName: "Customer Name",
    deliveryAddress: "Delivery Address",
    contactNumber: "Contact Number",
    orderDate: "Order Date",
    orderTime: "Order Time",
    estimatedDeliveryTime: "Estimated Delivery Time",
    amount: "Amount",
    discount: "Discount",
    taxes: "Taxes",
    totalCost: "Total Cost",
    orderProcessed: "Your order is being processed",
    orderNotFound: "We are sorry!",
    orderNotFoundDetail:
      "Our team kept looking for your order again and again but was not able to find it. If you think this is a mistake, please contact us.",
    confirmInFewMints: "The restaurant will confirm your order in few minutes",
    orderNumber: "Order Number",
    invoice: "Invoice",
    unitCost: "Unit Cost",
    needHelp: "Need Help?",
    qustionsOnOrder:
      "Have questions regarding your order? You can contact us here",
    addressHere: "Address Here",
    poweredByMunchesters: "Powered by Synevry",
    continueToShop: "Continue To Shop",
    deliveryCharges: "Delivery Charges",
  },
};
