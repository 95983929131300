// export const serverUrl = "http://192.168.100.115:3002";
// export const serverUrl = "http://192.168.100.77:3002";
// export const serverUrl = 'https://munchapi.travoir.com/';
// export const serverUrl = "https://server.houseoffries.synevry.com/";
// export const serverUrl = "https://test.munch.travoir.com/";

// export const identifier = 'hcp'
// export const serverUrl = "http://192.168.18.10:3002";
export const identifier = 'ghof';
// export const identifier = 'qcart'
// export const serverUrl = "https://api.quackcart.com";
// export const serverUrl = "https://test.munch.travoir.com/";
export const serverUrl = 'https://server.synevry.bitrupt.co/';
// export const serverUrl = 'http://localhost:3002/';
