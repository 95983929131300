/* eslint-disable import/no-anonymous-default-export */
export default {
  Menu: {
    alertError: 'Fehler! Etwas ist schief gelaufen.',
    category: 'Kategorie',
    yourCart: 'Ihr Warenkorb',
    emptyCart:
      'Ihr Warenkorb ist leer. Legen Sie zunächst einige Artikel in Ihren Warenkorb',
    discount: 'Rabatt',
    subTotal: 'Zwischensumme',
    placeOrder: 'BESTELLUNG AUFGEBEN',
    selectOne: 'Wähle eins',
    specialInstructions: 'spezielle Anweisungen',
    addToCart: 'IN DEN WARENKORB LEGEN',
    viewCart: 'WARENKORB ANSEHEN',
    orderSummary: 'Bestellübersicht',
    applyPromo: 'Angebot bewerben',
    details: 'Einzelheiten',
    paymentMethod: 'Zahlungsmethode',
    cashOnDelivery: 'Barzahlung bei Lieferung',
    deliveryDetails: 'Lieferdetails',
    firstName: 'Vorname',
    lastName: 'Nachname',
    address: 'Adresse',
    city: 'Stadt',
    area: 'Bereich',
    selectCity: 'Stadt wählen',
    selectArea: 'Bereich auswählen',
    contactNumber: 'Kontakt Nummer',
    emailAddress: 'E-Mail-Addresse',
    deliveryInstructions: 'Lieferanweisungen',
    cancel: 'Abbrechen',
    confirmOrder: 'Bestellung bestätigen',
    delivery: 'Lieferung',
    takeaway: 'Mitnehmen',
    tax: 'MwSt',
    itemTotal: 'Artikel Gesamt',
    allFieldsMandatory: 'alle Felder sind erforderlich',
    deliveryCharges: 'Lieferkosten',
    closedMessage:
      'Unser Team hat sich eine Auszeit genommen, um Zeit mit ihren Familien zu verbringen, aber wir werden morgen wieder da sein, um Ihren Appetit zu stillen.',
  },
  Receipt: {
    customerName: 'Kundenname',
    deliveryAddress: 'Lieferadresse',
    contactNumber: 'Kontakt Nummer',
    orderDate: 'Bestelldatum',
    orderTime: 'Bestellzeitpunkt',
    estimatedDeliveryTime: 'Voraussichtliche Lieferzeit',
    amount: 'Höhe',
    discount: 'Rabatt',
    taxes: 'Steuern',
    totalCost: 'Gesamtkosten',
    orderProcessed: 'Ihre Bestellung wird bearbeitet',
    orderNotFound: 'Es tut uns leid!',
    orderNotFoundDetail:
      'Unser Team hat immer wieder nach Ihrer Bestellung gesucht, konnte sie aber nicht finden. Wenn Sie der Meinung sind, dass dies ein Fehler ist, kontaktieren Sie uns bitte.',
    confirmInFewMints:
      'Das Restaurant wird Ihre Bestellung in wenigen Minuten bestätigen',
    orderNumber: 'Bestellnummer',
    invoice: 'Rechnung',
    unitCost: 'Kosten pro Einheit',
    needHelp: 'Brauchen Sie Hilfe?',
    qustionsOnOrder:
      'Haben Sie Fragen zu Ihrer Bestellung? Hier können Sie uns kontaktieren',
    addressHere: 'Adresse hier',
    poweredByMunchesters: 'Angetrieben von Synevry',
    continueToShop: 'Weiter zum Shop',
    deliveryCharges: 'Lieferkosten',
  },
};
