import React from "react";
import logo from "./../logo.svg";
import custom from "./Component.module.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSelector } from "react-redux";

function HeaderCarousel() {
  const businessConfig = useSelector(
    (state) => state.businessReducer.businessConfig
  );
  // console.log("Business Config: Header Carousel: ", businessConfig);
  return (
    <Carousel
      autoPlay
      infiniteLoop
      // centerMode //
      interval={3000}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      stopOnHover={false}
      swipeable={true}
    >
      {businessConfig?.images?.carousel?.map((headerImage) => {
        return (
          <div>
            <img
              src={`${headerImage}`}
              alt="carousel"
              className={custom.headerCarousel}
            />
            {/* <p style={{position: 'absolute', zIndex: 12345, color: '#fff', top: '22%', left: '35%', fontFamily:'Dancing Script',  transform: "translate(-50%, -50%)", fontSize: 72, transform: 'rotate(-22deg)'}}>House Of Fries</p> */}
          </div>
        );
      })}
    </Carousel>
  );
}

// return (
// <Carousel.Item interval={1000}>
//   <img
//     // className={custom.headerCarousel}
//     style={{width: '100%', height: '400px', objectFit: 'cover'}}
//     src={headerImage}
//     alt="slide"
//   />
// </Carousel.Item>
// );
// <div>
//   <img
//     src={'https://www.muenchen.de/media/fs/restaurants.jpg'}
//     className={custom.headerCarousel}
//     alt="Restaurant"
//   />
// </div>

export default HeaderCarousel;
