import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { fetchAreas } from '../redux/actions/addressInfo';
import { useSelector, useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import custom from './Component.module.css';
import { useParams } from 'react-router-dom';
import { identifier } from '../config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '35px',
  borderRadius: '20px',
  p: 4,
};

function TimmingModal({ open, handleClose, formValues, setFormValues, setDeliveryCharges }) {

  // let { businessIdentifier } = useParams();
  let businessIdentifier = identifier;


  const areas = useSelector((state) => state.addressReducer.areas);
  let groupedAreas = areas.reduce(
    (h, obj) =>
      Object.assign(h, { [obj.city]: (h[obj.city] || []).concat(obj) }),
    {}
  );

  const dispatch = useDispatch();

  const [city, setCity] = useState('');
  const [area, setArea] = useState('');

  async function fetchAreaInfo() {
    await dispatch(fetchAreas(businessIdentifier));
  }

  useEffect(() => {
    fetchAreaInfo();
  }, []);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={custom.selectCityModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Your Area
          </Typography>
          <div style={{ marginTop: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id="select_city">Select Your City</InputLabel>
              <Select
                labelId="select_city"
                onChange={(e) => {
                  setCity(e.target.value)
                  setFormValues({
                    ...formValues,
                    selectedCity: {
                      ...formValues['selectedCity'],
                      value: e.target.value,
                    },
                  });
                }}
                value={city}
                label="Select Your City"
                MenuProps={{ PaperProps: { sx: { maxHeight: '30vh' } } }}
              >
                {Object.keys(groupedAreas).map((cityIns) => (
                  <MenuItem value={cityIns}>{cityIns}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginTop: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id="select_area">Select Your Area</InputLabel>
              <Select
                onChange={(e) => {
                  setArea(e.target.value)
                  setFormValues({
                    ...formValues,
                    selectedArea: {
                      ...formValues['selectedArea'],
                      value: e.target.value,
                    },
                  });
                }}
                labelId="select_area"
                value={area}
                disabled={city === ''}
                label="Select Your Area"
                MenuProps={{ PaperProps: { sx: { maxHeight: '30vh' } } }}
              >
                {Object.values(groupedAreas).length &&
                  groupedAreas[city]?.map((area) => (
                    <MenuItem value={area.area} >{area.area}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <Button
            style={{ marginTop: '20px' }}
            variant="contained"
            disabled={area === ''}
            onClick={() => {
              localStorage.setItem('location', JSON.stringify({ city, area }));
              if (area) {
                let selectedArea = groupedAreas[city].find(
                  (val) => val.area === area
                );

                localStorage.setItem(
                  'deliveryCharges',
                  JSON.stringify(selectedArea.delivery_charges)
                );
                localStorage.setItem('deliveryTime', JSON.stringify(selectedArea?.delivery_time))
              }
              handleClose();
            }}
            className={custom.modalLocationButton}
          >
            Continue To Shop
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default TimmingModal;
