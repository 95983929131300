import { SET_BUSINESS, SET_MENU, SET_ORDER_DETAILS, SET_BUSINESS_CONFIG, SET_CURRENCY } from '../actions/types';

const initialState = {
  business: { businessName: "Synevry" },
  menu: [],
  orderDetails: {},
  businessConfig: {},
  currencyData: {},
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESS:
      return {
        ...state,
        ...action.data,
      };
    case SET_MENU:
      return {
        ...state,
        ...action.data,
      };
    case SET_ORDER_DETAILS:
      return {
        ...state,
        ...action.data,
      }
    case SET_BUSINESS_CONFIG:
      return {
        ...state,
        businessConfig: action.payload
      }
      case SET_CURRENCY:
      return {
        ...state,
        currencyData: action.payload
      }

    default:
      return state;
  }
};

export default businessReducer;