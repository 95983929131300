import React, { useEffect, useState } from "react";
import { Route, Routes, Switch } from "react-router-dom";
import MenuPage from "../pages/MenuPage";
import { useTranslation } from "react-i18next";
import ReceiptPage from "../pages/ReceiptPage";
import { serverUrl } from "../config";
import custom from "./Navigation.module.css";
import { useDispatch, useSelector } from "react-redux";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import axios from "axios";
import { SET_CURRENCY } from "../redux/actions/types";

function RootNavigation() {
  const { i18n } = useTranslation();
  const [currencyData, setCurrencyData] = useState({});
  const dispatch = useDispatch();
  const businessConfig = useSelector(
    (state) => state.businessReducer.businessConfig
  );

  const excerpt = (str) => {
    if (str?.length > 0) {
      let stringLength = str?.length;
      if (stringLength > 87) {
        str = str.substring(0, 87) + " ... ";
      }
      return str;
    }
    return "";
  };
  console.log("business", businessConfig);
  useEffect(() => {
    (async () => {
      const currencyData = await axios({
        url: `/api/vendor/currency/${businessConfig.currency_id}`,
        method: "GET",
        baseURL: serverUrl,
      });
      let temp = currencyData?.data?.payload;
      if (temp.length > 0) {
        temp = temp[0];
        dispatch({
          type: SET_CURRENCY,
          payload: { symbol: temp.symbol, prefix: temp.is_prefix },
        });
        window.localStorage.setItem(
          "currencyData",
          JSON.stringify({ symbol: temp.symbol, prefix: temp.is_prefix })
        );
      }
    })();
  }, [businessConfig]);

  const selected = (val) => {
    i18n.changeLanguage(val);
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<MenuPage currencyData={currencyData} />} />
        <Route
          path="/receipt/:orderIdentifier"
          element={<ReceiptPage currencyData={currencyData} />}
        />
      </Routes>

      <div
        style={{
          left: 0,
          bottom: 0,
          right: 0,
          height: 40,
          width: "100%",
        }}
      >
        <div className={custom.footer}>
          <span className={custom.footerInfo}>
            <p
              style={{ marginRight: 10, display: "flex", alignItems: "center" }}
            >
              <b>{<LocalPhoneOutlinedIcon style={{ marginRight: 5 }} />} </b>
              {businessConfig?.contact_number}
            </p>
            <p
              style={{ marginRight: 10, display: "flex", alignItems: "center" }}
            >
              <b>{<MailOutlineIcon style={{ marginRight: 5 }} />} </b>
              {businessConfig?.email}
            </p>
          </span>
          <span style={{ fontSize: "12px" }}>
            {excerpt(businessConfig?.description)}
          </span>
          <span className={custom.footerCredits}>
            <a
              style={{
                color: "var(--secandory-color)",
                textDecoration: "none",
              }}
            >
              By Synevry With ❤️
            </a>
          </span>
          {/* <span
            onClick={() => selected('en')}
            style={{
              fontWeight: i18n.language === 'en' ? 'bold' : 'normal',
            }}
            className={custom.language}
          >
            Eng
          </span>
          <span
            onClick={() => selected('de')}
            style={{
              fontWeight: i18n.language === 'de' ? 'bold' : 'normal',
            }}
            className={custom.language}
          >
            German
          </span> */}
        </div>
      </div>
    </div>
  );
}

export default RootNavigation;
