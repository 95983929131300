import Dialog from "@material-ui/core/Dialog";
import { useState, useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import custom from "./ItemModal.module.css";
import Add from "@material-ui/icons/Add";
import noImage from "./../assets/no-image.png";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Remove, Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import { showPrice } from "../resources/currency";

function ItemModal({
  ModalClose,
  showModal,
  selected_item,
  select_value_handler,
  select_radio_handler,
  checkbox_state,
  radio_state,
  cart_handler,
  cartCalculator,
  item_quantity_state,
  item_quantity_handler,
  cart_state,
  isDisabled,
  setCartToLocalStorage,
  instruction,
  setInstruction,
}) {
  const [totalPrice, setTotalPrice] = useState(selected_item?.item_price || 0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  }, [error]);

  const handleAddToCart = () => {
    let requiredCheck;
    let itemMatched = false;
    let obj = {};
    let currentCart = cart_state;
    let currentItem = {
      ...selected_item,
      options: [...radio_state, ...checkbox_state],
      item_quantity: item_quantity_state,
      instruction,
    };
    // ------- Handle Required --------- //
    currentItem.questions.map((question) => {
      if (question.is_required) {
        obj[question.question_id] = false;
        question.options.map((option) => {
          // console.log("111Current Item: ", currentItem.options.some(item => item.option_id === option.option_id));
          if (
            currentItem.options.some(
              (item) => item.option_id === option.option_id
            )
          ) {
            obj[question.question_id] = true;
          }
        });
      }
    });
    // console.log("Object to validate: ", obj);
    requiredCheck = Object.values(obj).every((v) => v === true);
    // console.log("Required Check: ", requiredCheck);

    if (requiredCheck) {
      // console.log("Current Item: ", currentItem);
      // ------- Sort Options by Option Id --------
      currentItem?.options?.sort(function (b, a) {
        return -(a.option_id - b.option_id);
      });

      // ----- Create A unique id using itemid & optionid -----
      let itemUniqueId = currentItem.item_id.toString();
      currentItem.options.forEach((value) => {
        itemUniqueId += value.option_id;
      });
      // console.log("ItemUinqueID: ", itemUniqueId);

      // --------- Assign unique Id to current item -----
      currentItem = { ...currentItem, itemUniqueId: itemUniqueId };
      const newCart = currentCart?.map((value) => {
        if (value.itemUniqueId === currentItem.itemUniqueId) {
          // console.log("**** Item Id Matched ******  ");
          itemMatched = true;
          return {
            ...value,
            item_quantity: value.item_quantity + item_quantity_state,
          };
        } else {
          return { ...value };
        }
      });
      if (!itemMatched) {
        newCart.push(currentItem);
      }
      // currentCart.push(currentItem);
      currentCart = [...newCart];
      console.log('currentCart',currentCart)
      cartCalculator(currentCart);
      setCartToLocalStorage(currentCart);
      cart_handler(JSON.parse(JSON.stringify(currentCart)));
      ModalClose();
    } else {
      // ------
      setError(true);
    }
  };

  const updateTotalPrice = () => {
    let price = selected_item?.item_price;
    let discount = selected_item?.discounted_price;
    let total = price;
    let discountTotal = discount;
    radio_state.map((option) => {
      total += option.option_price;
      discountTotal += option.option_price;
    });
    checkbox_state.map((option) => {
      total += option.option_price;
      discountTotal += option.option_price;
    });
    setTotalPrice(discountTotal);
  };


  const handleCheckboxChange = (option) => {
    select_value_handler(option);
  }

  const handleRadioChange = (option) => {
    select_radio_handler(option);
  }

  useEffect(() => {
    updateTotalPrice();
  }, [radio_state, checkbox_state, showModal]);


  return (
    <Dialog
      onClose={() => {
        ModalClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={showModal}
      className={custom.dialogBox}
    >
      {/* <MuiDialogTitle
        id="customized-dialog-title"
        onClose={() => ModalClose()}
        className={custom.dialogTitle}
      >*/}
      {/* </MuiDialogTitle> */}
      <MuiDialogContent className={custom.dialogBoxContent}>
        <div className={custom.dialogTitleBody}>
          <img
            src={
              selected_item?.image_link ? selected_item?.image_link : noImage
            }
            className={custom.dialogTitleImage}
          />
          <div className={custom.dialogTitleBottom}></div>
          <div className={custom.dialogTitleBottom2}></div>
          <div className={custom.dialogTitleContent}>
            <div className={custom.dialogTitleContentData}>
              <h4 className={custom.dialogTitleHeading}>
                {selected_item?.item_title}
              </h4>
              <p style={{ padding: 0, margin: 0, fontSize: 14 }}>
                {selected_item?.discounted_price &&
                selected_item.discounted_price !== selected_item?.item_price ? (
                  <p>
                    {showPrice(selected_item?.discounted_price)}{" "}
                    <strike>{showPrice(selected_item?.item_price)}</strike>{" "}
                  </p>
                ) : (
                  <p>
                    <b> {showPrice(selected_item?.item_price)}</b>
                  </p>
                )}
              </p>
            </div>
            <p className={custom.dialogTitlePara2}>
              {selected_item?.item_description}
            </p>
          </div>

          <div
            onClick={() => {
              ModalClose();
            }}
            className={custom.dialogTitleClose}
          >
            <Close />
          </div>
        </div>
        {error ? (
          <>
            <Alert
              severity="error"
              style={{ position: "sticky", top: 0, zIndex: 111, marginLeft: 8 }}
            >
              You Need To Select Required Options in order to proceed
            </Alert>
          </>
        ) : null}
        {selected_item &&
          selected_item.questions
            .sort((b, a) => b.sort_position - a.sort_position)
            .map((question, index) => (
              <>
                {/* {let quesArray = question.sort((a, b) => b.sort_position - a.sort_position)} */}
                <div
                  style={{
                    borderTop:
                      index !== 0
                        ? "solid rgba(0,0,0,0.05) 1px"
                        : "solid transparent 1px",
                  }}
                  className={custom.dialogContentBox}
                >
                  {/* {console.log("Question: ", question)} */}
                  <h4 style={{ flex: 1 }}>{question.question_title}</h4>
                  {question.is_required ? (
                    <p
                      style={{
                        fontSize: 12,
                        border: "1px solid #A2291B",
                        padding: 3,
                        borderRadius: 5,
                      }}
                    >
                      {question.is_required ? "* Required" : "Select One"}
                    </p>
                  ) : null}
                </div>
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  {question.v_type === "checkbox" ? (
                    <FormGroup>
                      {question.options.map((option, optionIndex) => (
                        <div className={custom.dialogContentForm}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  !!checkbox_state.filter(
                                    (q) =>
                                      q.q_id === question.question_id &&
                                      q.option_id === option.option_id
                                  ).length
                                }
                                name={option.option}
                                onChange={(e) => {
                                  handleCheckboxChange({
                                    ...option,
                                    q_id: question.question_id,
                                    q_type: question.v_type,
                                  });
                                  // select_value_handler({
                                  //   ...option,
                                  //   q_id: question.question_id,
                                  //   q_type: question.v_type,
                                  // });
                                }}
                              />
                            }
                            label={option.option}
                            // value={JSON.stringify(option)}
                          />
                          <span>{`+ ${showPrice(option.option_price)}`}</span>
                        </div>
                      ))}
                    </FormGroup>
                  ) : (
                    <>
                      <FormGroup>
                        <RadioGroup
                          aria-label={question.question_title}
                          name={question.question_title}
                        >
                          {question.options.map((option, optionIndex) => (
                            <div className={custom.dialogContentForm}>
                              <FormControlLabel
                                value={JSON.stringify(option)}
                                control={
                                  <Radio
                                    checked={
                                      !!radio_state.filter(
                                        (q) =>
                                          q.q_id === question.question_id &&
                                          q.option_id === option.option_id
                                      ).length
                                    }
                                    onChange={(e) => {
                                      handleRadioChange({
                                        ...option,
                                        q_id: question.question_id,
                                        q_type: question.v_type,
                                      });
                                      // select_radio_handler({
                                      //   ...option,
                                      //   q_id: question.question_id,
                                      //   q_type: question.v_type,
                                      // });
                                    }}
                                  />
                                }
                                label={option.option}
                                // value={JSON.stringify(option)}
                              />
                              <span>{`+ ${showPrice(
                                option.option_price
                              )}`}</span>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormGroup>
                    </>
                  )}
                </div>
                
              </>
            ))}
            <div className={custom.instructionBox}>
                  <h4 className={custom.instructionHeading}>Special instructions</h4>
                  <p className={custom.instructionSubHeading}>Any specific preferences? Let the restaurant know.</p>
                  <textarea className={custom.instructionTextArea}value={instruction}onChange={(e)=> setInstruction(e.target.value)} placeholder="e.g. No mayo" rows={5} maxLength={250}></textarea>
                </div>
      </MuiDialogContent>
      {!isDisabled && (
        <MuiDialogActions className={custom.DialogActions}>
          <div className={custom.itemButtons}>
            <div className={custom.dialogActionsBody}>
              <button
                variant="contained"
                disableElevation
                onClick={() => {
                  let quantity = item_quantity_state - 1;
                  if (quantity >= 1) {
                    item_quantity_handler(quantity);
                  }
                }}
                disabled={item_quantity_state === 1 || isDisabled}
                className={custom.dialogActionsButton}
              >
                <Remove className={custom.dialogActionsRemove} />
              </button>
              <input
                type="number"
                value={item_quantity_state}
                min={1}
                max={10}
                className={custom.dialogActionsInput}
                readOnly
                // onChange={(e) => { setQuantity(e.target.value) }}
              />
              <button
                onClick={() => {
                  let quantity = item_quantity_state + 1;
                  item_quantity_handler(quantity);
                }}
                variant="contained"
                disableElevation
                disabled={isDisabled}
                className={custom.dialogActionsButton}
              >
                <Add className={custom.dialogActionsAdd} />
              </button>
            </div>
            <div style={{ flex: 1 }} />

            <Button
              onClick={() => {
                handleAddToCart();
              }}
              variant="contained"
              className={custom.placeOrderButton}
              disableElevation
            >
              Add To Cart - {showPrice(totalPrice)}
            </Button>
          </div>
        </MuiDialogActions>
      )}
    </Dialog>
  );
}

export default ItemModal;
