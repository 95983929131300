import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import noImage from "./../assets/no-image.png";
import custom from "./Component.module.css";
import { showPrice } from "../resources/currency";

function MenuItem(props) {
  const [showMore, setShowMore] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const excerpt = (str) => {
    let stringLength = str.length;
    if (stringLength > 80) {
      str = str.substring(0, 100) + " ... ";
    }
    return str;
  };
  return (
    <div
      className={custom.menuItem}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        borderRadius: "8px",
        // border: isHovered ? "2px solid black" : "2px solid #fff",
        boxShadow: isHovered
          ? "0 4px 8px rgba(0, 0, 0, 0.2)"
          : "0 2px 4px rgba(0, 0, 0, 0.3)",
        transition: "border 0.3s ease, box-shadow 0.3s ease",
        cursor: "pointer",
        transform: isHovered ? "scale(1.05)" : "scale(1)",
        transition: "transform 0.5s ease, box-shadow 0.3s ease",
      }}
      onClick={() => {
        props.onAddToCart();
        props.setInstruction("");
      }}
    >
      <div className={custom.menuItemBox}>
        <img
          alt={props?.details?.item_title}
          src={props?.details?.image_link ? props.details.image_link : noImage}
          style={{
            width: props?.details.image_link ? "100%" : "75%",
            height: props?.details.image_link ? "100%" : "75%",

            filter: isHovered ? "blur(2px)" : "none",
            transition: "filter 0.3s ease",
            objectFit: "cover",
          }}
        />
      </div>
      <div className={custom.centered}>
        <b className={custom.itemTitle}>
          {excerpt(props?.details?.item_title)}
        </b>
        <div className={custom.discription}>
          <p className={custom.lessItemDescription}>
            {props.details.item_description
              ? showMore
                ? props.details.item_description
                : excerpt(props.details.item_description)
              : "All good things start with Nutella! This beautiful Nutella Cake starts This beautiful Nutella "}
          </p>
        </div>
        {props.details.discounted_price < props.details.item_price ? (
          <p className="price" style={{ marginTop: "-20px" }}>
            {showPrice(props.details.discounted_price)}

            <strike>{showPrice(props.details.item_price)}</strike>
          </p>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p
              className="price"
              style={{
                width: "50%",
                marginTop: "-5px",
                backgroundColor: "#fcb416",
                color: "#074842",
                fontSize: ".8rem",
                fontWeight: 700,
              }}
            >
              {showPrice(props.details.item_price)}
            </p>
          </div>
        )}
      </div>
      {props.isDisabled ? null : (
        <button
          disabled={props.isDisabled}
          className={custom.menuItemAddItem}
          style={{ cursor: "pointer" }}
        >
          <AddIcon color="white" style={{ color: "white" }} />
        </button>
      )}
    </div>
  );
}

export default MenuItem;
