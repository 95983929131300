import { createTheme } from '@material-ui/core/styles';

export const munchesterTheme = createTheme({
  spacing: 8,
  shape: {
    // borderRadius: 8,
  },
  MuiButtonBase: {
    root: {
      margin: '10px',
      padding: '40px',
    },
  },
  palette: {
    action: {
      disabledBackground: 'rgba(13, 73, 15, 0.5)',
      disabled: '#CCC'
    },
    secondary: {
      main: '#A2291B',
      contrastText: '#fff', // button text white instead of black

    },
    primary: {
      main: '#A2291B',
      contrastText: '#fff', // button text white instead of black
    },

  },
  overrides: {
    MuiOutlinedInput: {
      root: {

        "& $input": {
          padding: "15px 14px"
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #A2291B",
          borderRadius: "5px 5px 0 0"
        },
      }
    },

    MuiButton: {
      root: {
        padding: "10px 5px",
        ":disabled": {
          backgroundColor: 'red'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Roboto',
    color: '#f3f3f3',
    h1: {
      fontWeight: 500,
      fontSize: 23,
      '@media (min-width:600px)': {
        fontSize: 28,
      },
      '@media (max-width:600px)': {
        fontSize: 18,
      },
    },
    h2: {
      fontSize: 28,
      fontWeight: 'bold',

    },
    h3: {
      fontWeight: 500,

    },
    button: {
      spacing: 4,
      textTransform: 'none',
      color: 'white',
      fontFamily: 'Roboto',

    },
  },
});