const { io } = require('socket.io-client');

const socket = io('https://server.synevry.bitrupt.co', {
  cors: {
    origin: 'https://synevry.bitrupt.co',
    credentials: true,
  },
  transports: ['websocket'],
});

export default socket;
