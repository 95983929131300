export const GET_BUSINESS = 'GET_BUSINESS';
export const SET_BUSINESS = 'SET_BUSINESS';

export const SET_MENU = 'SET_MENU';
export const SET_CART = 'SET_CART';

export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const SET_BUSINESS_CONFIG = 'SET_BUSINESS_CONFIG';



export const SET_AREAS = 'SET_AREAS';

export const SET_CURRENCY = 'SET_CURRENCY';







