/* eslint-disable jsx-a11y/alt-text */
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import HeaderCarousel from '../components/HeaderCarousel';
import useWindowSize from '../helperFunctions/useWindowSize';
import {
  PhoneOutlined,
  LocationOnOutlined,
  ChatOutlined,
  ReceiptOutlined,
} from '@material-ui/icons';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import receipt from './../assets/receipt.png';
import recieptLeft from './../assets/receipt-left.jpg';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import socket from '../resources/socket';
import { fetchOrderDetails } from '../redux/actions/business';
import { useDispatch, useSelector } from 'react-redux';
import custom from './ReceiptPage.module.css';
import LinearProgress from '@mui/material/LinearProgress';
import { showPrice } from '../resources/currency';

function ReceiptPage(props) {
  const { t } = useTranslation();
  const [deliveryCharges, setDeliveryCharges] = useState(null);
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState(null);
  const size = useWindowSize();
  const dispatch = useDispatch();
  const receiptData = useSelector(
    (state) => state.businessReducer.orderDetails
  );
  const location = JSON.parse(localStorage.getItem('location'));
  const localStorageArea = JSON.parse(localStorage.getItem('area'));
  // const localStorageDeliveryTime = JSON.parse(localStorage.getItem('deliveryTime'))
  const businessConfig = useSelector(
    (state) => state.businessReducer.businessConfig
  );
  useEffect(() => {
    localStorageArea.forEach((element) => {
      if (element.area === location.area) {
        if (element.delivery_time) {
          let timeToSet = element.delivery_time.split(' ');
          let minutesToAdd = Number(timeToSet[0]);
          let estimatedTime =
            new Date(receiptData.creation_time).getTime() +
            minutesToAdd * 60000;
          setEstimatedDeliveryTime(new Date(estimatedTime).toLocaleString());
          return 1;
        }
        // console.log("Time To Add: ", Number(timeToSet[0]));
      }
    });
    // if(localStorageDeliveryTime !== null){
    // }
  }, [receiptData]);
  useEffect(() => {
    if (businessConfig) {
      document.documentElement.style.setProperty(
        '--secondary-color',
        businessConfig?.secondary_color
      );
      document.documentElement.style.setProperty(
        '--primary-color',
        businessConfig?.primary_color
      );
    }
  }, [businessConfig]);

  const [loader, setLoader] = useState(true);
  const [summary, setSummary] = useState([
    { title: 'Receipt:customerName', dataKey: 'customer' },
    { title: 'Receipt:deliveryAddress', dataKey: 'complete_address' },
    { title: 'Receipt:contactNumber', dataKey: 'contact_no' },
    {
      title: 'Receipt:orderDate',
      dataKey: 'creation_time',
      transform: (val) => new Date(val).toDateString(),
    },
    {
      title: 'Receipt:orderTime',
      dataKey: 'creation_time',
      transform: (val) => {
        let creation_date = new Date(val).toLocaleString();
        return creation_date?.split(', ')[1];
      },
    },
    // {
    //   title: 'Receipt:estimatedDeliveryTime',
    //   dataKey: 'creation_time',
    //   // content: () => {
    //   //   // new Date().toTimeString()
    //   //     let estimatedTime = new Date(receiptData.creation_time).getTime() + estimatedDeliveryTime * 60000
    //   //   return (new Date(estimatedTime).toTimeString())
    //   // },
    //   transform: (val) => {
    //     let estimatedTime = new Date(val).getTime() + estimatedDeliveryTime * 60000
    //     console.log("Estimated Time : ", estimatedTime);
    //     return (new Date(estimatedTime).toTimeString())
    //   }
    // },
  ]);

  const [invoice, setInvoice] = useState([]);
  const [invalidOrderId, setInvalidOrderId] = useState(false);
  let { orderIdentifier } = useParams();

  useEffect(() => {
    getReceipt();
  }, []);

  useEffect(() => {
    createInvoice();
  }, [receiptData]);

  let deliveryChargesLocal = JSON.parse(
    localStorage.getItem('deliveryCharges')
  );
  useEffect(() => {
    if (deliveryChargesLocal !== null) {
      setDeliveryCharges(deliveryChargesLocal);
    }
  }, [deliveryChargesLocal]);

  async function getReceipt() {
    try {
      await dispatch(fetchOrderDetails(orderIdentifier));
      socket.on('order_' + orderIdentifier, async (data) => {
        console.log('order status from socket', data); // x8WIv7-mJelg7on_ALbx
        await dispatch(fetchOrderDetails(orderIdentifier));
      });
      setLoader(false);
      // navigate('/dashboard')
    } catch (err) {
      setLoader(false);
      setInvalidOrderId(true);
      alert(t('Menu:alertError'));
    }
  }

  function createInvoice() {
    let discountedAmount = 0;
    let totalDiscount = 0;
    let receiptArr = [];
    let totalTax = 0;
    let totalAmountExcTax = 0;
    if (receiptData?.items) {
      Object.entries(receiptData?.items).map((item) => {
        receiptArr.push({
          title: item[1].item,
          quantity: item[1].item_qty,
          totalCost: parseFloat(
            item[1].item_qty * item[1].actual_price
          ).toFixed(2),
          type: 'item',
          options: item[1].options,
          id: item[0],
          instruction: item[1].instruction,
        });
        totalTax += Math.ceil(
          (item[1].actual_price / 100) * item[1].tax_rate * item[1].item_qty
        );
        // totalTax +=
        //  Math.ceil( item[1].item_qty * item[1].actual_price * (item[1].tax_rate / 100));
        totalAmountExcTax += item[1].item_qty * item[1].actual_price;
        discountedAmount += item[1].item_qty * item[1].item_price;
        if (item[1].options) {
          Object.entries(item[1].options).map((option) => {
            for (let i = 0; i < option[1].length; i++) {
              console.log('123123123', option[1][i]);
              totalAmountExcTax += option[1][i].option_price * item[1].item_qty;
              discountedAmount += option[1][i].option_price * item[1].item_qty;
              totalTax +=
                (option[1][i].option_price *
                  item[1].item_qty *
                  item[1].tax_rate) /
                100;
              // totalTax += Math.ceil(
              //   option[1][i].option_price *
              //   item[1].item_qty *
              //   (item[1].tax_rate / 100)); //if tax to be included on option otherwise comment
            }
          });
        }
      });
      totalDiscount = totalAmountExcTax - discountedAmount;
      receiptArr.push({
        title: 'Receipt:discount',
        totalCost: totalDiscount,
        type: 'discount',
      });
      if (businessConfig.is_taxable) {
        receiptArr.push({
          title: 'Receipt:taxes',
          totalCost: Math.ceil(totalTax),
          type: 'tax',
        });
      }
      receiptArr.push({
        title: 'Receipt:deliveryCharges',
        totalCost: receiptData?.delivery_charges,
        type: 'deliveryCharges',
      });
      if (businessConfig.is_taxable) {
        receiptArr.push({
          title: 'Receipt:totalCost',
          sub: '(incl. Tax)',
          totalCost:
            Math.ceil(totalAmountExcTax - totalDiscount + totalTax) +
            Math.ceil(receiptData?.delivery_charges),
          type: 'total',
        });
      } else {
        receiptArr.push({
          title: 'Receipt:totalCost',
          totalCost:
            Math.ceil(totalAmountExcTax - totalDiscount) +
            Math.ceil(receiptData?.delivery_charges),
          type: 'total',
        });
      }
      setInvoice(receiptArr);
    }
  }

  function status_text(status) {
    return status.split('-')[0].trim().toLowerCase();
  }

  function orderStatus_background() {
    if (receiptData && Object.keys(receiptData).length > 0) {
      switch (status_text(receiptData.order_status)) {
        case 'rejected':
          return 'red';
          break;
        case 'preparing':
          return '#E0AE24';
          break;
        case 'pending':
          return 'orange';
          break;
        default:
          return 'green';
          break;
      }
    } else {
      return 'red';
    }
  }

  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div
      style={{
        minHeight: size.height,
      }}
      className={custom.receiptPage}
    >
      <div className={custom.receiptPageHeader}>
        <div>
          {/* <Button
        variant="outlined"
        disableElevation
        className={custom.customOrderButton}
        >{t('receipt:continueToShop')}</Button> */}
        </div>
        <div className={custom.receiptPageLogo}>
          <img
            src={businessConfig?.images?.logo?.[0]}
            className={custom.receiptPageHeaderLogo}
          />
        </div>
      </div>
      {/* <HeaderCarousel /> */}

      <Grid
        container
        justifyContent='space-evenly'
        style={{
          backgroundImage: `url(${receipt})`,
          minHeight: size.height - 70,
        }}
        className={custom.receiptPageBody}
      >
        <Grid lg={7} md={10} sm={10} xs={10} className={custom.receiptProfile}>
          <Grid container className={custom.receiptProfileData}>
            <Grid lg={5} md={4} sm={3} xs={12}>
              <img src={recieptLeft} className={custom.receiptProfileImage} />
            </Grid>
            {invalidOrderId ? (
              <Grid
                lg={6}
                md={7}
                sm={8}
                xs={12}
                className={custom.receiptProfileInvalid}
              >
                <div className={custom.receiptProfileInvalidOrder}>
                  <Typography variant={'h4'} style={{ fontWeight: 'bold' }}>
                    {t('Receipt:orderNotFound')}
                  </Typography>

                  <div className={custom.receiptProfileInvalidOrderDetail} />

                  <Typography variant={'body1'}>
                    {t('Receipt:orderNotFoundDetail')}
                  </Typography>
                </div>
              </Grid>
            ) : (
              <Grid
                lg={6}
                md={7}
                sm={8}
                xs={12}
                className={custom.receiptProfileValid}
              >
                <div className={custom.receiptProfileValidOrder}>
                  {receiptData?.order_status === 'Delivered' ||
                  receiptData?.order_status?.split(' ')[0] === 'Rejected' ? (
                    <>
                      <Typography variant={'h4'} style={{ fontWeight: 'bold' }}>
                        {receiptData?.order_status === 'Delivered' ? (
                          <span>Your Order is Delivered</span>
                        ) : (
                          <span>Your Order is Rejected</span>
                        )}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant={'h4'} style={{ fontWeight: 'bold' }}>
                        {/* {t('Receipt:orderProcessed')} */}
                        {receiptData?.order_status === 'Pending' ? (
                          <span>Thank You for Ordering</span>
                        ) : (
                          <span>Your Order is Being Prepared</span>
                        )}
                      </Typography>

                      <Typography variant={'body1'}>
                        {/* {t('Receipt:confirmInFewMints')} */}
                        {receiptData?.order_status === 'Pending' ? (
                          <span>
                            The restaurant will confirm your order in few
                            minutes
                          </span>
                        ) : (
                          <span>
                            Your Order will soon dispatch to rider once it's
                            completed
                          </span>
                        )}
                      </Typography>
                    </>
                  )}
                  <Typography
                    variant={'h6'}
                    style={{
                      fontWeight: '500',
                      // color: "var(--secondary-color)",
                    }}
                  >
                    {t('Receipt:orderNumber')} #{orderIdentifier}
                  </Typography>
                  {receiptData?.order_status === 'Delivered' ||
                  receiptData?.order_status?.split(' ')[0] === 'Rejected' ? (
                    <div className={custom.receiptProfileDataTop} />
                  ) : (
                    <div style={{ marginTop: 10 }}>
                      {receiptData?.order_status === 'Pending' ? (
                        <LinearProgress color='inherit' />
                      ) : (
                        <LinearProgress color='success' />
                      )}
                    </div>
                  )}
                </div>

                {summary.map((item, index) => {
                  return (
                    <div style={{ paddingBottom: 10 }}>
                      <Typography
                        variant={'body2'}
                        style={{ fontWeight: '500', opacity: 0.4 }}
                      >
                        {t(item.title)}
                      </Typography>
                      <Typography
                        variant={'body1'}
                        style={{ fontWeight: 'bold' }}
                      >
                        {item.dataKey
                          ? item.transform
                            ? item.transform(receiptData[item.dataKey])
                            : receiptData[item.dataKey]
                          : item.content}
                      </Typography>
                    </div>
                  );
                })}
                <div style={{ paddingBottom: 10 }}>
                  <Typography
                    variant={'body2'}
                    style={{ fontWeight: '500', opacity: 0.4 }}
                  >
                    Estimated Delivery Time
                  </Typography>
                  <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                    {estimatedDeliveryTime?.split(', ')[1]}
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid lg={3} md={10} sm={10} xs={10} style={{ marginTop: 10 }}>
          {!invalidOrderId && (
            <div className={custom.receiptInvoice}>
              <div className={custom.receiptInvoiceHead}>
                <ReceiptOutlined className={custom.receiptInvoiceLogo} />
                <span
                  style={{
                    backgroundColor: orderStatus_background(),
                  }}
                  className={custom.receiptOrderStatus}
                >
                  {receiptData.order_status}
                </span>
              </div>
              <Typography variant={'h4'} style={{ fontWeight: 'bold' }}>
                {t('Receipt:invoice')}
              </Typography>
              {invoice.map((item, idx) => {
                return (
                  <>
                    <div className={custom.receiptInvoiceBody}>
                      <Typography
                        variant={'body1'}
                        style={{ wordBreak: 'break-all', fontWeight: 'bold' }}
                      >
                        {item.type === 'item' ? `${item.quantity + 'x '}` : ''}
                        {item.type === 'item' ? item.title : t(item.title)}
                        {item.sub !== undefined ? (
                          <span style={{ fontSize: 10 }}> {item.sub}</span>
                        ) : null}
                      </Typography>
                      <Typography
                        variant={'body1'}
                        className={custom.receiptInvoiceTotalAmount}
                      >
                        {showPrice(item.totalCost)}
                      </Typography>
                    </div>

                    {item.type === 'item' && (
                      <>
                        {item.options &&
                          Object.entries(item.options).map((option, idx) => {
                            return (
                              <div>
                                <hr
                                  style={{
                                    opacity: 0.05,
                                    marginTop: 2,
                                    marginBottom: 2,
                                  }}
                                />
                                <Typography
                                  variant={'body1'}
                                  style={{ fontSize: 14 }}
                                >
                                  {/* {option[0]} */}
                                </Typography>
                                {option[1].map((optionItem, idx) => {
                                  return (
                                    <div
                                      className={custom.receiptInvoiceAmount}
                                    >
                                      <Typography
                                        variant={'body1'}
                                        style={{ fontSize: 14 }}
                                      >
                                        {optionItem.answer}
                                      </Typography>
                                      <Typography
                                        variant={'body1'}
                                        className={custom.receiptInvoiceTotal}
                                      >
                                        {showPrice(
                                          Number(
                                            optionItem.option_price *
                                              item.quantity
                                          )
                                        )}
                                      </Typography>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        <div>
                          <Typography
                            variant={'body1'}
                            style={{ fontSize: 14, wordBreak: 'break-word' }}
                          >
                            {item.instruction}
                          </Typography>
                        </div>
                        <hr style={{ opacity: 0.2 }} />
                      </>
                    )}
                  </>
                );
              })}
            </div>
          )}

          <div
            style={{
              marginTop: invalidOrderId ? 0 : 15,
              marginBottom: 15,
            }}
            className={custom.receiptChatBox}
          >
            <ChatOutlined className={custom.receiptChatBoxLogo} />

            <Typography variant={'h4'} style={{ fontWeight: 'bold' }}>
              {t('Receipt:needHelp')}
            </Typography>
            <Typography variant={'body1'}>
              {t('Receipt:qustionsOnOrder')}
            </Typography>
            <div style={{ paddingTop: 15 }}>
              <div className={custom.receiptChatBoxData}>
                <PhoneOutlined style={{ fontSize: 22 }} />
                <Typography
                  variant={'body2'}
                  className={custom.receiptChatBoxDetails}
                >
                  {businessConfig?.contact_number}
                </Typography>
              </div>

              <div className={custom.receiptChatBoxData}>
                <LocationOnOutlined style={{ fontSize: 22 }} />
                <Typography
                  variant={'body2'}
                  className={custom.receiptChatBoxDetails}
                >
                  {businessConfig?.city} , {businessConfig?.country}
                </Typography>
              </div>
              <div className={custom.receiptChatBoxData}>
                <EmailOutlinedIcon style={{ fontSize: 22 }} />
                <Typography
                  variant={'body2'}
                  className={custom.receiptChatBoxDetails}
                >
                  {businessConfig?.email}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <Grid container className={custom.receiptPageFooter}>
        <Grid item>
          <Typography
            variant={'body2'}
            className={custom.receiptPageFooterItem}
          >
            {t('Receipt:poweredByMunchesters')}
          </Typography>
        </Grid>
      </Grid> */}
    </div>
  );
}

export default ReceiptPage;
