import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import RootNavigation from './navigaton/RootNavigation';
import { ThemeProvider } from '@material-ui/core';
import { munchesterTheme } from './resources/theme';
import { useSelector } from 'react-redux';
import socket from './resources/socket';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    socket.on('connect', () => {
      console.log('socket id', socket.id); // to test if connected to server socket or not
    });
    socket.on('disconnect', () => {
      console.log('socket disconnected'); // to test if connected to server socket or not
      alert(
        'Connection to the server has broken, please refresh the web to get order updates in realtime.'
      );
    });
  }, []);

  const businessConfig = useSelector(
    (state) => state.businessReducer.businessConfig
  );
  munchesterTheme.palette.primary.main = businessConfig?.secondary_color;
  return (
    <ThemeProvider theme={munchesterTheme}>
      <Router>
        <RootNavigation />
      </Router>
    </ThemeProvider>
  );
}

export default App;
